jQuery(function() {
function cssLoaded(callback) {
    if ($("[data-loadcss]").length) {
        let css_wait = setInterval(function(){
            if (body.css("margin-left") === "0px") {
                clearInterval(css_wait);
                callback();
            }
        },0);
    } else {
        callback();
    }
};
function fn_cookiesconsent_event(type, callback) {
    if (type === "approve") {
        localStorage.setItem("cookieconsent", "approve");
        fn_cookiesconsent_append("all");
    } else if (type === "performance") {
        localStorage.setItem("cookieconsent", "approve");
        localStorage.setItem("cookieconsent_type", "performance");
        fn_cookiesconsent_append("performance");
    } else if (type === "marketing") {
        localStorage.setItem("cookieconsent", "approve");
        localStorage.setItem("cookieconsent_type", "marketing");
        fn_cookiesconsent_append("all");
    } else if (type === "decline") {
        localStorage.setItem("cookieconsent", "decline");
        localStorage.removeItem("cookieconsent_type");
        fn_cookiesconsent_delete();
        if (callback) {
            callback();
        }
    }
}

function fn_cookiesconsent_delete() {
    document.cookie.split(";").forEach(function(c) {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
}

function fn_cookiesconsent_append(type) {
    $("script[data-cookieconsent]").each(function(){
        let elm = $(this);

        if (type === "all") {
            let script = document.createElement("script");
            elm.each(function() {
                $.each(this.attributes, function() {
                    if(this.specified) {
                        if (this.name.indexOf("data-cookieconsent") === -1 && this.name.indexOf("type") === -1) {
                            script.setAttribute(this.name, this.value);
                        }
                    }
                });
            });
            script.innerHTML = elm[0].innerHTML;

            if (elm.closest("body").length) {
                document.body.appendChild(script);
            } else if (elm.closest("head").length) {
                document.head.appendChild(script);
            }

            elm.remove();
        } else {
            elm = elm.filter(`[data-cookieconsent="${type}"]`);

            if (elm.length) {
                let script = document.createElement("script");
                elm.each(function() {
                    $.each(this.attributes, function() {
                        if(this.specified) {
                            if (this.name.indexOf("data-cookieconsent") === -1 && this.name.indexOf("type") === -1) {
                                script.setAttribute(this.name, this.value);
                            }
                        }
                    });
                });
                script.innerHTML = elm[0].innerHTML;

                if (elm.closest("body").length) {
                    document.body.appendChild(script);
                } else if (elm.closest("head").length) {
                    document.head.appendChild(script);
                }

                elm.remove();
            }
        }
    });
}

(function($) {
    const fn = $(".part_cookie_consent");

    window.lui_cookiesconsent = {};
    window.lui_cookiesconsent.fn_cookieconsent_init = fn_cookieconsent_init;

    function fn_cookieconsent_init() {
        if (localStorage.getItem('cookieconsent') === "approve") {
            let type = localStorage.getItem('cookieconsent_type');
            if (type !== null) {
                if (type === "performance") {
                    fn_cookiesconsent_append("performance");
                } else if (type === "marketing") {
                    fn_cookiesconsent_append("all");
                }
            } else {
                fn_cookiesconsent_append("all");
            }
        }
    }

    if (fn.length) {
        if (localStorage.getItem('cookieconsent') == null) {
            fn_cookiesconsent_append("all");
            setTimeout(function(){
                cssLoaded(function(){
                    fn.addClass("mod--active mod--animate");
                });
            },1500);
        }
        fn_cookieconsent_init();

        fn.on('click', function() {
            fn.addClass("mod--mobile-show");
        });

        fn.find("[data-cookies-approve]").on('click', function() {
            fn_cookiesconsent_event("approve");
            fn.removeClass("mod--animate");
            setTimeout(function(){
                fn.removeClass("mod--active").remove();
            },500);
        });

        fn.find("[data-cookies-decline]").on('click', function() {
            fn_cookiesconsent_event("performance");
            fn.removeClass("mod--animate");
            setTimeout(function(){
                fn.removeClass("mod--active").remove();
            },500);
        });
    }
})(jQuery);;
(function($) {
    const fn = $(".part_form_cookies");

    if (fn.length) {
        if (localStorage.getItem('cookieconsent') !== null) {
            if (localStorage.getItem('cookieconsent') === "approve") {
                let type = localStorage.getItem('cookieconsent_type');
                if (type !== null) {
                    if (type === "performance") {
                        fn.find(`input[value="performance"]`).prop("checked", true);
                    } else {
                        fn.find(`input[value="approve"]`).prop("checked", true);
                    }
                } else {
                    fn.find(`input[value="approve"]`).prop("checked", true);
                }
            } else if(localStorage.getItem('cookieconsent') === "decline") {
                fn.find(`input[value="decline"]`).prop("checked", true);
            }
        }
        fn.on("submit", function(e){
            e.preventDefault();

            let value = fn.serializeArray()[0].value;

            if (value === "approve") {
                fn_cookiesconsent_event("marketing");
                location.reload();
            } else if (value === "performance") {
                fn_cookiesconsent_event("performance");
                location.reload();
            } else if (value === "decline") {
                fn_cookiesconsent_event("decline", function(){
                    location.reload();
                });
            }
        });
    }
})(jQuery);;
});